import dynamic from "next/dynamic";
import Head from "next/head";

import Header from "../components/Header";

const Banner = dynamic(() => import("../components/Banner"));
const ArticleList = dynamic(() => import("../components/ArticleList"));
const Footer = dynamic(() => import("../components/Footer"));

export default function Home() {
  const description =
    "An award-winning story studio focused on finding, financing, producing, and publishing original, in-depth journalism.";
  const bannerCopy =
    "In-depth. Immersive. </br>Impactful. Independent. </br><div style='margin-bottom:20px;'></div>This is Long Lead, journalism </br>without compromise.";
  const articles = [
    {
      title: "An Unnatural Disaster",
      image: "/unnatural-disaster.jpeg",
      imageAlt: "An Unnatural Disaster Preview Image",
      vimeoId: "",
      pill: "MULTIMEDIA",
      excerpt:
        "One of the world’s newest cities, Canaan emerged from the rubble of an apocalyptic earthquake and the long, slow collapse of Haiti’s government. Now, on the edge of survival, its citizens are trapped, fighting to reclaim their lives.",
      href: "/article/an-unnatural-disaster",
      body: "",
    },
    {
      title: "Home of the Brave",
      image: "/vets-3.jpg",
      imageAlt: "Home of the Brave Preview Image",
      vimeoId: "",
      pill: "MULTIMEDIA",
      excerpt:
        "Los Angeles is the homeless veteran capital of America. But in the 1950s, around 5,000 disabled vets lived on a vast campus on some of its most valuable land. Now — after decades of malfeasance, neglect, and graft — they’re fighting their own government to take their home back.",
      href: "/article/home-of-the-brave",
      body: "",
    },
    {
      title: "Long Shadow",
      image: "/longshadow-s3.gif",
      imageAlt: "Long Shadow Season 3 Preview Image",
      vimeoId: "gif",
      pill: "PODCAST",
      excerpt:
        "Through a series of riveting, complex narratives, this podcast series makes sense of what people know — and what they thought they knew — about the most pivotal moments in U.S. history, including Waco, Columbine, 9/11, January 6, and beyond.",
      href: "/article/long-shadow",
      body: "",
    },
    {
      title: "Lifting Ukraine",
      image: "/lifting-ukraine.jpg",
      imageAlt: "Lifting Ukraine Image",
      vimeoId: "gif",
      pill: "PHOTOGRAPHY",
      excerpt:
      "Since Russia’s 2022 invasion of Ukraine, world-record-setting powerlifter Anna Kurkurina has saved and sheltered countless injured and orphaned animals, ensuring that in her country’s fight for independence, more than just the strong will survive.",
      href: "/article/lifting-ukraine",
      body: "",
    },
    {
      title: "The Catch",
      image: "/kraft_carousel.jpg",
      imageAlt: "The Catch Preview Image",
      vimeoId: "gif",
      pill: "MULTIMEDIA",
      excerpt:
        "In the <i>Mad Men</i> era of magazine journalism, Virginia Kraft was deadly with a rifle and penned epic, deeply reported features. Among the most important sports journalists of her time, the pioneering adventure writer chiseled early cracks into publishing’s male-dominated world. So why hasn’t anyone heard of her?",
      href: "/article/the-catch",
      body: "",
    },
    {
      title: "The People vs. Rubber Bullets",
      image: "/rb-recirculation.png",
      imageAlt: "Querdenken Everything Featured Image",
      vimeoId: "749135281",
      videoUrl:
        "https://player.vimeo.com/video/749135281?h=de39055c3f&speed=0&muted=1&autoplay=1&loop=1&background=1&app_id=122963",
      pill: "MULTIMEDIA",
      excerpt:
        "For more than 50 years, less-lethal munitions like rubber bullets, bean-bag rounds, pepper balls, and tear gas canisters have injured, disfigured, and killed people. Don’t look away; the victims have built a powerful case against these weapons.",
      href: "/article/people-vs-rubber-bullets",
      body: "",
    },
    {
      title: "Querdenken Everything",
      image: "/querdenken-small.png",
      imageAlt: "Querdenken Everything Featured Image",
      vimeoId: "715785016",
      // videoUrl:
      //   "https://download-video.akamaized.net/playback/4d8d462d-8208-4d5c-97d9-00223b14785f/b92b991d-a70b9b7d?__token__=st=1654658459~exp=1654672859~acl=%2Fplayback%2F4d8d462d-8208-4d5c-97d9-00223b14785f%2Fb92b991d-a70b9b7d%2A~hmac=e4804a3e88db31324829a3d38aad557ee819820dcc70464bd81ba52e7acb8f74",
      videoUrl:
        "https://player.vimeo.com/video/715785016?h=90429334f1&speed=0&muted=1&autoplay=1&loop=1&background=1&app_id=122963",
      pill: "INTERACTIVE",
      excerpt:
        "Months before the U.S. Capitol insurrection, COVID skeptics resisting Germany’s public health measures stormed the Reichstag. The story of that day and its consequences — as told by those involved — is a true tale of misinformation, its causes and its effects.",
      href: "/article/querdenken-everything",
      body: "",
    },
    {
      title: "The Depths She’ll Reach",
      image: "/depths-shell-reach.png",
      imageAlt: "The Depths She’ll Reach Featured Image",
      vimeoId: "647929690",
      videoUrl:
        "https://player.vimeo.com/video/647929690?h=de39055c3f&speed=0&muted=1&autoplay=1&loop=1&background=1&app_id=122963",
      pill: "MULTIMEDIA",
      excerpt:
        "Only a few people — all men — have dived deeper than Alenka Artnik on just one breath. And soon, she may surpass them all. This immersive profile recounts how she emerged from obscurity and mental health struggles to become one of the world’s most elite athletes.",
      href: "/article/the-depths-shell-reach",
      body: "",
    },
  ];
  return (
    <div>
      <Head>
        <title>Long Lead</title>
        <meta property="og:title" content="Long Lead" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Long Lead" />
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content="https://longlead.com/LL_FB_5.png" />
        <link rel="canonical" href="https://longlead.com/" />
        <link rel="preconnect" href="https://player.vimeo.com" />
        <link rel="preconnect" href="https://i.vimeocdn.com" />
        <link rel="preconnect" href="https://f.vimeocdn.com" />
      </Head>
      <Header />
      <main className="main-container home">
        <Banner bannerCopy={bannerCopy} />
        <div id="footer-trigger" />
        <ArticleList articles={articles} />
      </main>
      <Footer />
    </div>
  );
}
