import styled from "styled-components";

export const HeaderStyles = styled.div`
  position: fixed;
  top: 0 !important;
  left: 0;
  width: 100px;
  height: 100vh;
  z-index: 99999;

  &.is-active {
    z-index: 999999999;
  }

  @media only screen and (max-width: 1079px) {
    width: 100%;
    height: 0;
  }

  .content {
    nav {
      position: absolute;
      padding-top: 20vh;
      left: 14px;
      top: 0;
      height: 100vh;
      width: 36px;
      z-index: 9999;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      > a {
        position: relative;
        color: #1f00b7;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-transform: Uppercase;
        transform: rotate(-90deg);
        transform-origin: center;
        white-space: nowrap;
        left: 2px;
        width: 115px;
        text-align: center;
        cursor: pointer;

        span {
          display: inline-block;
          transition: 0.5s ease all;
          padding-bottom: 2px;
          border-bottom: 2px solid transparent;
        }

        &:hover,
        &.active {
          span {
            border-bottom: 2px solid #1f00b7;
          }
        }

        &.white {
          color: #fff;

          &:hover,
          &.active {
            span {
              border-bottom: 2px solid #fff;
            }
          }
        }

        @media only screen and (max-width: 1439px) {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }

    .logo {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 99999;
      padding: 18px 20px;
      margin-bottom: 20px;
      img {
        display: block;
        width: 38px;
        height: 38px;

        &.light-logo {
          display: none;
        }
        &.dark-logo {
          display: block;
        }
      }

      &.white {
        img.light-logo {
          display: block;
        }
        img.dark-logo {
          display: none;
        }
      }
    }

    .hamburger {
      position: absolute;
      top: 25px;
      right: 20px;
      z-index: 99999;
      padding: 0;
      width: 20px;
      display: none;

      @media only screen and (max-width: 1079px) {
        display: block;
      }

      &.white {
        .hamburger-inner,
        .hamburger-inner:after,
        .hamburger-inner:before {
          background-color: #fff;
        }
      }
    }

    .hamburger-inner,
    .hamburger-inner:after,
    .hamburger-inner:before {
      height: 1.6px;
      width: 20px;
    }

    .socials {
      a {
        display: block;
        transform: none;
        margin-bottom: 25px;
        border: none !important;
        margin-left: 3px;
        svg {
          g,
          path {
            fill: #1f00b7;
          }
        }

        &:hover {
          color: #000;
          svg path,
          svg g,
          svg rect {
            fill: #000;
          }
        }

        &.white {
          color: #fff !important;
          svg path,
          svg g,
          svg rect {
            fill: #fff !important;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1079px) {
    .content nav {
      display: none;
    }

    &.is-active {
      .logo {
        img.light-logo {
          display: none !important;
        }
        img.dark-logo {
          display: block !important;
        }
      }
    }

    .header-container.is-active {
      position: fixed;
      background: #efedea;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
      visibility: visible;
      transition: 0.5s ease all;
      z-index: 999999999;

      .content nav {
        display: flex;
        position: relative;
        text-align: center;
        width: 100%;
        justify-content: center;
        padding: 0;
        left: 0;
        > a {
          transform: none;
          display: block;
          font-size: 30px;
          line-height: 30px;
          margin-bottom: 30px;
          width: auto;
          text-transform: none;
        }
        .socials {
          position: absolute;
          bottom: 30px;
          display: flex;
          a {
            margin-left: 10px;
            margin-right: 10px;
            margin-bottom: 0;
          }
        }
      }

      .content {
        .hamburger-inner,
        .hamburger-inner:after,
        .hamburger-inner:before {
          background: #1f00b7 !important;
        }

        .socials a svg g,
        .socials a svg path {
          fill: #1f00b7 !important;
        }

        nav > a {
          color: #1f00b7 !important;
        }

        nav > a:hover span,
        nav > a.active span {
          border-bottom: 2px solid #1f00b7 !important;
        }
      }
    }
  }
`;
