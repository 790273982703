export const injectTitleToSvg =
  (title = "title", desc = "description") =>
  (svg) => {
    const descElement = document.createElementNS("https://longlead.com", "desc");
    descElement.innerHTML = desc;
    svg.prepend(descElement);

    const titleElement = document.createElementNS("https://longlead.com", "title");
    titleElement.innerHTML = title;
    svg.prepend(titleElement);
  };
