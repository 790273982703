/* eslint-disable eqeqeq */
/* eslint-disable */
import React, { useEffect, useState } from "react";
import Link from "next/link";
import { ReactSVG } from "react-svg";
import { useRouter } from "next/router";
import { HeaderStyles } from "./HeaderStyles";
import VisuallyHidden from "../VisuallyHidden";
import { injectTitleToSvg } from "../../utils/injectTitleToSvg";

const Header = ({ customClass = "lightbg" }) => {
  const [menuActive, setMenuActive] = useState(false);
  const router = useRouter();

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const scrollTo = (path) => {
    if (typeof window === "undefined") {
      return;
    }
    setMenuActive(false);
    const id = path.replace("#", "");
    const el = window.document.getElementById(id);
    if (!el) {
      return;
    }
    const r = el.getBoundingClientRect();
    window.top.scroll({
      top: pageYOffset + r.top,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const path = window.location.hash;
    if (path && path.includes("#")) {
      setTimeout(() => {
        scrollTo(path);
      }, 50);
    }
  }, []);

  return (
    <HeaderStyles id={"header-" + customClass} className={menuActive ? "is-active" : ""}>
      <div className={"header-container " + (menuActive ? "is-active" : "")}>
        <div className={"content " + customClass}>
          <Link href="/" scroll={false} legacyBehavior>
            <a className="logo" id="item1">
              <img
                width="300"
                height="300"
                alt="Long Lead Light Logo"
                src="/hp_logo.svg"
                className="light-logo"
                loading="lazy"
              />
              <img
                width="300"
                height="300"
                alt="Long Lead Dark Logo"
                src="/hp_logo_blue.svg"
                className="dark-logo"
                loading="lazy"
              />
            </a>
          </Link>
          <button
            id="item2"
            className={" hamburger hamburger--collapse " + (menuActive ? "is-active" : "")}
            type="button"
            onClick={() => toggleMenu()}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
            <VisuallyHidden>Open menu</VisuallyHidden>
          </button>
          <nav className="menu">
            {router.pathname == "/" || router.pathname == "/#stories" ? (
              <a
                id="item3"
                href="#stories"
                className="stories-menu-link"
                onClick={(e) => {
                  e.preventDefault();
                  scrollTo("#stories");
                }}
              >
                <span>Stories</span>
              </a>
            ) : (
              <Link href="/#stories" scroll={false} legacyBehavior>
                <a id="item3" className=" stories-menu-link">
                  <span>Stories</span>
                </a>
              </Link>
            )}
            <Link href="/about-us" scroll={false} legacyBehavior>
              <a id="item4" className={router.pathname == "/about-us" ? "active" : ""}>
                <span>About Us</span>
              </a>
            </Link>
            <Link href="/news" scroll={false} legacyBehavior>
              <a id="item5" className={router.pathname == "/news" ? "active" : ""}>
                <span>News</span>
              </a>
            </Link>
            <Link href="/newsletters" scroll={false} legacyBehavior>
              <a id="item6" className={router.pathname == "/newsletters" ? "active" : ""}>
                <span>Newsletters</span>
              </a>
            </Link>
            <div className="socials">
              <a
                id="item7"
                className="linkedin"
                href="https://www.linkedin.com/company/long-lead/"
                target="_blank"
                rel="noreferrer"
              >
                <ReactSVG
                  src="/linkedin-icon.svg"
                  aria-label="linkedin"
                  role="img"
                  beforeInjection={injectTitleToSvg("linkedin icon", "a linkedin icon")}
                />
                <VisuallyHidden>Visit our Linkedin</VisuallyHidden>
              </a>
              <a
                id="item8"
                className="facebook"
                href="https://www.facebook.com/Long-Lead-100806058498601"
                target="_blank"
                rel="noreferrer"
              >
                <ReactSVG
                  src="/facebook-icon.svg"
                  aria-label="facebook"
                  role="img"
                  beforeInjection={injectTitleToSvg("facebook icon", "a facebook icon")}
                />
                <VisuallyHidden>Visit our Facebook</VisuallyHidden>
              </a>
              <a
                id="item9"
                className="instagam"
                href="https://www.instagram.com/longlead/"
                target="_blank"
                rel="noreferrer"
              >
                <ReactSVG
                  src="/instagram-icon.svg"
                  aria-label="instagram"
                  role="img"
                  beforeInjection={injectTitleToSvg("instagram icon", "a instagram icon")}
                />
                <VisuallyHidden>Visit our Instagram</VisuallyHidden>
              </a>
              <a
                id="item10"
                className="tiktok"
                href="https://www.tiktok.com/@long_lead"
                target="_blank"
                rel="noreferrer"
              >
                <ReactSVG
                  src="/tiktok-icon.svg"
                  aria-label="tiktok"
                  role="img"
                  beforeInjection={injectTitleToSvg("tiktok icon", "a tiktok icon")}
                />
                <VisuallyHidden>Visit our Tiktok</VisuallyHidden>
              </a>
            </div>
          </nav>
        </div>
      </div>
    </HeaderStyles>
  );
};

Header.propTypes = {};

export default Header;
